// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Routes from './routes/Routes';
import { getLoggedInUser, generateHearbeat } from "./helpers/authUtils";

import InteractionMonitor from "./InteractionMonitor";

import SessionExpiryWarningModal from "./components/SessionExpiryWarningModal"
import SessionExpiredModal from "./components/SessionExpiredModal";

// Themes
import './assets/scss/DefaultTheme.scss';

/**
 * Main app component
 */
class App extends Component {
    constructor(props) {
        super(props);

        this.registerHeartbeat = this.registerHeartbeat.bind(this);
    }

    componentDidMount() {
        setInterval(this.registerHeartbeat, 30000);
    }

    registerHeartbeat() {
        var loggedInUser = getLoggedInUser();

        if (!this.props.isSessionExpired && loggedInUser != null) {
            generateHearbeat();
        }
    }

    render() {
        return (
            <React.Fragment>
                <SessionExpiryWarningModal />
                <SessionExpiredModal  />
                <InteractionMonitor  />
                <Routes onRouteChange={this.registerHeartbeat} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSessionExpired: state.CurrentSession.sessionExpired
    }
};


export default connect(mapStateToProps)(App);
