import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import ErrorBoundary from './ErrorBoundary';

//ReactDOM.render(
//    <Provider store={configureStore()}>
//        <ErrorBoundary>
//            <App />
//        </ErrorBoundary>
//    </Provider>,
//    document.getElementById('root')
//);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={configureStore()}>
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
</Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
