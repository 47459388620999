// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

const defaultTokenRefreshMinutesBeforeExpiry = 15;

/**
 * Checks if user is authenticated. Parameter tokenRefreshMinutesBeforeExpiry specifies the minimum number of minutes it must be prior to 
 * token expiry for the token to be refreshed. If not supplied, a default value is used.
 */
const isUserAuthenticated = (tokenRefreshMinutesBeforeExpiry = defaultTokenRefreshMinutesBeforeExpiry, isSessionExpired = false, forceTokenRefresh = false, setSessionExpiryTimeFunc = null) => {
    var authenticated = false;
    const user = getLoggedInUser();
    // check that user object is present and it has a bearer token
    if (user && user.token) {
        const decoded = jwtDecode(user.token);
        //console.log("user.username", user.username);
        //console.log("decoded.unique_name", decoded.unique_name);

        // check that username in the object matches the decoded username in the bearer token
        if (user.username === decoded.unique_name) {
            if (forceTokenRefresh) {
                refreshToken(user.username, user.token, user.userVisitId);
                authenticated = true;
                setSessionExpiryTimeFunc(user.tokenExpiryMinutes);
                return authenticated;
            }

            const currentTime = Date.now() / 1000; // in seconds
            const expiryTime = decoded.exp; // in seconds
            //console.log('current time', currentTime);
            //console.log('expiryTime', expiryTime);

            // check token expiry date
            if (expiryTime < currentTime) {
                logout();
                authenticated = false;
            } else {
                // token not expired, check if needs refresh (below 15 mins)
                let diffMins = (expiryTime - currentTime) / 60; // in minutes

                var tokenRefreshMinutesBeforeExpiry = tokenRefreshMinutesBeforeExpiry || defaultTokenRefreshMinutesBeforeExpiry;

                if (diffMins <= tokenRefreshMinutesBeforeExpiry && !isSessionExpired) {
                    // token expiring in less than 15 minutes, refresh the token with new expiration time
                    refreshToken(user.username, user.token, user.userVisitId);
                    setSessionExpiryTimeFunc && setSessionExpiryTimeFunc(user.tokenExpiryMinutes);
                }

                authenticated = true;
            }
        }
        else {
            // invalid token // logout
            logout();
            authenticated = false;
        }
    }

    return authenticated;
};

const refreshToken = (username, token, userVisitId) => {
    const options = {
        body: JSON.stringify({ username, token, userVisitId }),
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + getUserToken()
        }
    };

    const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let fullUrl = baseUrl + '/portal/Auth/RefreshToken';

    return fetch(fullUrl, options)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error(response);
            }
        })
        .then(user => {
            // successful if there's a user and a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in a cookie to keep user logged in between page refreshes
                var cookies = new Cookies();
                cookies.set('.ASPNETAUTH2', JSON.stringify(user), { path: '/', secure: true });
            } else {
                throw new Error(user);
            }
        })
        .catch(error => {
            // log user out as we couldn't refresh the token
            //console.error(error);
            logout();
        });
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    var user = "";
    // check cookie first to see if user is already logged in
    var cookies = new Cookies();
    user = cookies.get('.ASPNETAUTH2', { path: '/' });
    user = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;

    if (user && user.token) {
        return user;
    }
    else {
        //logout();
        return user;

        // The react private route component renders a route component if the user is logged in, otherwise it redirects the user to the /login page.
        // The way it checks if the user is logged in is by checking that there is a user object in the cookie .ASPNETAUTH2
        // While it's possible to bypass this check by manually adding an object to cookie using browser dev tools, 
        // this would only give access to the client side component, 
        // it wouldn't give access to any real secure data from the server api because a valid authentication token(JWT) is required for this.
    }
};

const checkIfUserAcceptedExtTermsOfUse = () => {
    var user = "";
    // check cookie first to see if user is already logged in
    var cookies = new Cookies();
    user = cookies.get('.ASPNETAUTH2', { path: '/' });
    user = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;

    if (user) {
        return user.acceptedExtTermsOfUse;
    }
    else {
        return false;
    }
};

const getUserToken = () => {
    // return jwt token for authorization header
    const cookies = new Cookies();
    let user = cookies.get('.ASPNETAUTH2', { path: '/' });
    user = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;

    if (user && user.token) {
        return user.token;
    } else {
        return '';
    }
}

const getUserVisitId = () => {
    // return jwt token for authorization header
    const cookies = new Cookies();
    let user = cookies.get('.ASPNETAUTH2', { path: '/' });
    user = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;

    if (user && user.userVisitId) {
        return user.userVisitId;
    } else {
        return '';
    }
}

const getGenericAttributeFromToken = () => {
    var objGenericAttribute = {
        ExternalUserId: "",
        MobileActive: "",
        CustID: "",
        FinancialAccess: "",
        Admin: "",
        EmailVerified: "",
        CustomerAccess: "",
        TechnicalAccess: "",
        ServicedEntityAccess: ""
    };

    // check cookie first to see if user is already logged in
    var cookies = new Cookies();
    var user = cookies.get('.ASPNETAUTH2', { path: '/' });
    user = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;

    if (user && user.token) {
        const decoded = jwtDecode(user.token);

        // check that username in the object matches the decoded username in the token
        if (user.username === decoded.unique_name) {
            // it is a valid token, so extract parameters
            var userdata = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
            //console.log(userdata);

            // parse userData and display menus accordingly
            var userDataArray = userdata.split('~');

            for (var i = 0; i < userDataArray.length; i++) {
                var keyValuePair = userDataArray[i].split(':');

                if (keyValuePair.length > 1) {
                    switch (keyValuePair[0]) {
                        case "ExternalUserId":
                            objGenericAttribute.ExternalUserId = keyValuePair[1];
                            break;
                        case "MobileActive":
                            objGenericAttribute.MobileActive = keyValuePair[1];
                            break;
                        case "CustID":
                            objGenericAttribute.CustID = keyValuePair[1];
                            break;
                        case "FinancialAccess":
                            objGenericAttribute.FinancialAccess = keyValuePair[1];
                            break;
                        case "Admin":
                            objGenericAttribute.Admin = keyValuePair[1];
                            break;
                        case "EmailVerified":
                            objGenericAttribute.EmailVerified = keyValuePair[1];
                            break;
                        case "CustomerAccess":
                            objGenericAttribute.CustomerAccess = keyValuePair[1];
                            break;
                        case "TechnicalAccess":
                            objGenericAttribute.TechnicalAccess = keyValuePair[1];
                            break;
                        case "ServicedEntityAccess":
                            objGenericAttribute.ServicedEntityAccess = keyValuePair[1];
                            break;
                        default:
                            break;
                    };
                }

            }
        }

        return objGenericAttribute;
    }
}

/**
 * Logout the user
 */
const logout = () => {
    // clear cookies client side
    const cookies = new Cookies();
    cookies.remove('.ASPNETAUTH2', { path: '/' }); // bearer token

    // redirect to login page
    redirectToLoginPage();
};

const sessionExpired = () => {
    // clear cookies client side
    const cookies = new Cookies();
    cookies.remove('.ASPNETAUTH2', { path: '/' }); // bearer token
}

const redirectToLoginPage = () => {
    const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    const loginPageUrl = baseUrl + '/portal';
    window.location.replace(loginPageUrl);
};

const generateHearbeat = () => {
    let url = "/portal/Heartbeat/Register";

    let options = {
        ...{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + getUserToken(),
                'UserVisitId': getUserVisitId()
            }
        }, ...options
    };

    fetch(url, options)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error(response);
            }
        })
        .then(function (response) {
            // Anything to do?
        })
        .catch(function (errorResponse) {
            // Anything to do?
        });
};

export {
    isUserAuthenticated, getUserToken, getLoggedInUser, refreshToken, logout, getGenericAttributeFromToken,
    checkIfUserAcceptedExtTermsOfUse, sessionExpired, redirectToLoginPage, generateHearbeat
};
