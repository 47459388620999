import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getGenericAttributeFromToken } from '../helpers/authUtils';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const LoginInternal = React.lazy(() => import('../pages/auth/Login/login_internal'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Unsubscribe = React.lazy(() => import('../pages/auth/Unsubscribe'));

// Extranet Pages
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const AerCapTeam = React.lazy(() => import('../pages/AerCapTeam'));

// Lease Account
const LeaseAccountSummary = React.lazy(() => import('../pages/LeaseAccount'));
const PaymentHistory = React.lazy(() => import('../pages/LeaseAccount/PaymentHistory'));
const InterestCharges = React.lazy(() => import('../pages/LeaseAccount/InterestCharges'));
const RentCalculation = React.lazy(() => import('../pages/LeaseAccount/RentCalculation'));
const ViewInvoices = React.lazy(() => import('../pages/LeaseAccount/ViewInvoices'));
const LettersOfCredit = React.lazy(() => import('../pages/LeaseAccount/LettersOfCredit'));
//const Insurance = React.lazy(() => import('../pages/Insurance'));

// Fleet Summary
const FleetAircraft = React.lazy(() => import('../pages/FleetSummary/FleetAircraft'));
const FleetEngines = React.lazy(() => import('../pages/FleetSummary/FleetEngines'));

// Supplemental Rent
const FundBalances = React.lazy(() => import('../pages/SupplementalRent/FundBalances'));
const MonthlyReports = React.lazy(() => import('../pages/SupplementalRent/MonthlyReports'));
const Claims = React.lazy(() => import('../pages/SupplementalRent/Claims'));

// Utilisation
const Utilisation = React.lazy(() => import('../pages/Utilisation'));
const UtilisationReport = React.lazy(() => import('../pages/Utilisation/Reports'));

// Documents
const Publications = React.lazy(() => import('../pages/Documents/Publications'));

// Asset For Sale
const AssetForSale = React.lazy(() => import('../pages/AssetForSale/index'));
const AssetForSaleDetails = React.lazy(() => import('../pages/AssetForSale/Details'));
const AssetForSaleNDA = React.lazy(() => import('../pages/AssetForSale/NDADisplay'));

// TMS
const TechnicalProjects = React.lazy(() => import('../pages/TMS/TechnicalProjects'));
const TechnicalProjectDetails = React.lazy(() => import('../pages/TMS/TechnicalProjectDetails'));

// error pages
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }
            else {
                // User authenticated, now check user roles vs route allowed roles
                // get generic attribute flags
                var objGenericAttribute = getGenericAttributeFromToken();
                // hide/show routes based on route roles and user roles
                if (objGenericAttribute && roles && (
                    (roles.indexOf("GenericAccess") > -1
                        && ((roles.indexOf("ServicedEntityAccess") > -1 && objGenericAttribute.ServicedEntityAccess === "N") || roles.indexOf("ServicedEntityAccess") === -1)) // generic access with no serviced entity
                    || (roles.indexOf("CustomerAccess") > -1 && objGenericAttribute.CustomerAccess === "Y"
                        && ((roles.indexOf("ServicedEntityAccess") > -1 && objGenericAttribute.ServicedEntityAccess === "N") || roles.indexOf("ServicedEntityAccess") === -1)) // customer access with no serviced entity
                    || (roles.indexOf("FinancialAccess") > -1 && objGenericAttribute.FinancialAccess === "Y"
                        && ((roles.indexOf("ServicedEntityAccess") > -1 && objGenericAttribute.ServicedEntityAccess === "N") || roles.indexOf("ServicedEntityAccess") === -1)) // financial access with no serviced entity
                    || (roles.indexOf("TechnicalAccess") > -1 && objGenericAttribute.TechnicalAccess === "Y")
                    || (roles.indexOf("Admin") > -1 && objGenericAttribute.Admin === "Y"))
                ) {
                    // user role match one of the route roles so display page
                    return <Component {...props} />;
                }
                else
                {
                    // role not authorised so redirect to home page
                    return <Redirect to={{ pathname: '/' }} />;
                }
            }
        }}
    />
);


// roles
const allRoles = ["GenericAccess", "CustomerAccess", "FinancialAccess", "TechnicalAccess"];
const customerRoles = ["CustomerAccess"];
const financialRoles = ["FinancialAccess"];
const technicalRoles = ["TechnicalAccess"];
//const adminRoles = ["CustomerAccess", "FinancialAccess", "Admin"];
const servicedEntityRoles = ["ServicedEntityAccess"];


// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    //component: () => <Redirect to="/aercapteam" />, please note if you change this you need to change also Index.cshtml.cs
    route: PrivateRoute,
    roles: allRoles
};

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/login_internal',
            name: 'LoginInternal',
            component: LoginInternal,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/unsubscribe',
            name: 'Unsubscribe',
            component: Unsubscribe,
            route: Route,
        },
    ],
};

// Dashboard
const dashboardRoute = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'mdi mdi-speedometer mdi-24px',
    component: Dashboard,
    route: PrivateRoute,
    roles: allRoles
};

// Your AerCap Team
const teamRoutes = {
    path: '/aercapteam',
    name: 'Your AerCap Team',
    icon: 'mdi mdi-account-multiple mdi-24px',
    component: AerCapTeam,
    route: PrivateRoute,
    roles: allRoles
};

// Lease Account
const leaseAccountRoutes = {
    path: '/LeaseAccount',
    name: 'Lease Account',
    roles: [...financialRoles], // by adding servicedEntityRoles we restrict the section to Serviced Entity users
    icon: 'mdi mdi-bank mdi-24px',
    children: [
        {
            path: '/LeaseAccount/index',
            name: 'Lease Account Summary',
            component: LeaseAccountSummary,
            route: PrivateRoute,
            roles: [...financialRoles],
        },
        {
            path: '/LeaseAccount/PaymentHistory',
            name: 'Payment History',
            component: PaymentHistory,
            route: PrivateRoute,
            roles: [...financialRoles],
        },
        {
            path: '/LeaseAccount/InterestCharges',
            name: 'Interest Charges',
            component: InterestCharges,
            route: PrivateRoute,
            roles: [...financialRoles, ...servicedEntityRoles], // by adding servicedEntityRoles we restrict the section to Serviced Entity users
        },
        {
            path: '/LeaseAccount/RentCalculation',
            name: 'Rent Calculation',
            component: RentCalculation,
            route: PrivateRoute,
            roles: [...financialRoles],
        },
        {
            path: '/LeaseAccount/ViewInvoices',
            name: 'View Invoices',
            component: ViewInvoices,
            route: PrivateRoute,
            roles: [...financialRoles, ...servicedEntityRoles], // by adding servicedEntityRoles we restrict the section to Serviced Entity users
        },
        {
            path: '/LeaseAccount/LettersOfCredit',
            name: 'Letters of Credit',
            component: LettersOfCredit,
            route: PrivateRoute,
            roles: [...financialRoles],
        }
    ],
};

const FleetSummaryRoutes = {
    path: '/FleetSummary',
    name: 'Fleet Summary',
    roles: financialRoles,
    icon: 'mdi mdi-airplane mdi-24px',
    children: [
        {
            path: '/FleetSummary/Fleet/Aircraft',
            name: 'Aircraft',
            component: FleetAircraft,
            route: PrivateRoute,
            roles: [...financialRoles],
        },
        {
            path: '/FleetSummary/Fleet/Engines',
            name: 'Engines',
            component: FleetEngines,
            route: PrivateRoute,
            roles: [...financialRoles],
        }
    ]
};

// Supplemental Rent
const supplementalRentRoutes = {
    path: '/SupplementalRent',
    name: 'Supplemental Rent',
    roles: financialRoles,
    icon: 'mdi mdi-tag-plus mdi-24px',
    children: [
        {
            path: '/SupplementalRent/FundBalances',
            name: 'Fund Balances',
            component: FundBalances,
            route: PrivateRoute,
            roles: financialRoles,
        },
        {
            path: '/SupplementalRent/MonthlyReports',
            name: 'Monthly Billing Details',
            component: MonthlyReports,
            route: PrivateRoute,
            roles: financialRoles,
        },
        {
            path: '/SupplementalRent/Claims',
            name: 'Claims',
            component: Claims,
            route: PrivateRoute,
            roles: financialRoles,
        }
    ]
};

// Utilisation
const utilisationRoutes = {
    path: '/Utilisation',
    name: 'Utilisation',
    icon: 'mdi mdi-airplane-takeoff mdi-24px',
    roles: customerRoles,
    children: [
        {
            path: '/Utilisation/index',
            name: 'Utilisation Input',
            component: Utilisation,
            route: PrivateRoute,
            roles: [...customerRoles, ...servicedEntityRoles], // by adding servicedEntityRoles we restrict the section to Serviced Entity users
        },
        {
            path: '/Utilisation/Reports',
            name: 'Reports',
            component: UtilisationReport,
            route: PrivateRoute,
            roles: customerRoles,
        },
    ],
};

// Documents
const documentsRoutes = {
    path: '/Documents/Publications',
    name: 'Documents',
    icon: 'mdi mdi-file-document-box-multiple mdi-24px',
    component: Publications,
    route: PrivateRoute,
    roles: allRoles
};

// Asset For Sale Listing
const assetForSaleRoutes = {
    path: '/AssetForSale/index',
    name: 'Asset For Sale',
    icon: 'mdi mdi-bullhorn mdi-24px',
    component: AssetForSale,
    route: PrivateRoute,
    roles: allRoles
};

// Asset For Sale Listing Details
const assetForSaleDetailRoutes = {
    path: '/AssetForSale/Details',
    name: 'Asset For Sale Details',
    component: AssetForSaleDetails,
    route: PrivateRoute,
    roles: allRoles
};

// Asset For Sale Listing NDA
const assetForSaleListingNDARoutes = {
    path: '/AssetForSale/NDADisplay',
    name: 'Asset For Sale Listing NDA',
    component: AssetForSaleNDA,
    route: PrivateRoute,
    roles: allRoles
};

// Technical Projects
const tmsRoutes = {
    path: '/TMS/TechnicalProjects',
    name: 'Technical Projects',
    icon: 'mdi mdi-wrench mdi-24px',
    component: TechnicalProjects,
    route: PrivateRoute,
    roles: technicalRoles
};

// Technical Project Details
const technicalProjectDetailRoutes = {
    path: '/TMS/TechnicalProjectDetails',
    name: 'Technical Project Details',
    component: TechnicalProjectDetails,
    route: PrivateRoute,
    roles: technicalRoles
};


// error
const errorRoutes = {
    path: '/error',
    name: 'Error',
    roles: ServerError,
    children: [
        {
            path: '/error/error-500',
            name: 'Error - 500',
            component: ServerError,
            route: PrivateRoute,
            roles: allRoles
        },
        {
            name: 'Error - 404',
            component: ErrorPageNotFound,
            route: PrivateRoute,
            roles: allRoles
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    authRoutes,
    rootRoute,
    dashboardRoute,
    teamRoutes,
    //availabilityRoutes,
    leaseAccountRoutes,
    FleetSummaryRoutes,
    supplementalRentRoutes,
    utilisationRoutes,
    documentsRoutes,
    assetForSaleRoutes,
    //insuranceRoutes,
    tmsRoutes,
    technicalProjectDetailRoutes,
    assetForSaleDetailRoutes,
    assetForSaleListingNDARoutes,
    errorRoutes
];

const authProtectedRoutes = [
    dashboardRoute,
    teamRoutes,
    documentsRoutes,
    //availabilityRoutes,
    assetForSaleRoutes,
    leaseAccountRoutes,
    FleetSummaryRoutes,
    supplementalRentRoutes,
    utilisationRoutes,
    //insuranceRoutes,
    tmsRoutes
    //errorRoutes
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };