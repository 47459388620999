// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cookies } from 'react-cookie';

import { isUserAuthenticated, getLoggedInUser } from "./helpers/authUtils";

import {
    ACTIVATE_SESSION_EXPIRY_COUNTDOWN,
    SET_TOKEN_EXPIRY_COUNTDOWN_SECONDS,
    SET_LAST_INTERACTION_TIME,
    SET_LAST_SESSION_CHECK,
    SHOW_SESSION_EXPIRY_WARNING,
    UPDATE_TOKEN_EXPIRY_TIMINGS
} from './redux/currentSession/constants';

import { setSessionExpiryTime } from './redux/currentSession/actions';

// Themes
import './assets/scss/DefaultTheme.scss';

/**
 * Main app component
 */
class InteractionMonitor extends Component {
    constructor(props) {
        super(props);

        this.recordInteraction = this.recordInteraction.bind(this);
        this.updateTimingsFromCookie = this.updateTimingsFromCookie.bind(this);
        this.refreshTokenIfAppropriate = this.refreshTokenIfAppropriate.bind(this);
    }

    componentDidMount() {
        let that = this;

        document.addEventListener("click", function () {
            that.recordInteraction();
        });

        document.addEventListener("keyup", function () {
            that.recordInteraction();
        });

        setInterval(that.refreshTokenIfAppropriate, that.props.lastInteractionTimeCheckIntervalMS);
    }

    recordInteraction() {
        let lastInteractionTime = Date.now();
        this.props.setLastInteractionTime(lastInteractionTime);
    }

    updateTimingsFromCookie() {
        if (!this.props.timingsSetFromCookie) {
            var user = "";
            // check cookie first to see if user is already logged in
            var cookies = new Cookies();
            user = cookies.get('.ASPNETAUTH2', { path: '/' });
            user = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;

            if (user) {
                let tokenValidityCheckFrequencySeconds = user.tokenValidityCheckFrequencySeconds || this.props.tokenValidityCheckFrequencySeconds;
                let tokenRefreshMinutesBeforeExpiry = user.tokenRefreshMinutesBeforeExpiry || this.props.tokenRefreshMinutesBeforeExpiry;
                let tokenExpiryMinutes = user.tokenExpiryMinutes || this.props.tokenExpiryMinutes;
                let tokenExpiryCountdownSeconds = user.tokenExpiryCountdownSeconds || this.props.tokenExpiryCountdownSeconds;
                
                this.props.updateSessionExpiryTime(tokenExpiryMinutes);
                this.props.setTokenExpiryCountdownSeconds(tokenExpiryCountdownSeconds);
                
                this.props.updateTokenExpiryTimings({
                    timingsSetFromCookie: true,
                    tokenValidityCheckFrequencySeconds,
                    tokenRefreshMinutesBeforeExpiry,
                    tokenExpiryMinutes,
                    tokenExpiryCountdownSeconds
                });

                this.timingsSetFromCookie = true;
            }
        }
    }

    refreshTokenIfAppropriate() {
        var loggedInUser = getLoggedInUser();
        
        // Only do this if the session isn't expired and somebody is logged in.
        if (!this.props.isSessionExpired && loggedInUser != null) {
            this.updateTimingsFromCookie();

            const currentTimeInSeconds = Date.now() / 1000;
            const lastInteractionTimeInSeconds = this.props.lastInteractionTime / 1000;
            const lastSessionCheckInSeconds = this.props.lastSessionCheck / 1000;

            let secondsSinceLastInteraction = currentTimeInSeconds - lastInteractionTimeInSeconds;
            let secondsSinceLastCheck = currentTimeInSeconds - lastSessionCheckInSeconds;

            if (secondsSinceLastInteraction <= secondsSinceLastCheck) {
                if (secondsSinceLastCheck > this.props.tokenValidityCheckFrequencySeconds - 1) {
                    isUserAuthenticated(this.props.tokenRefreshMinutesBeforeExpiry, this.props.isSessionExpired, false, this.props.updateSessionExpiryTime);
                    
                    this.props.setLastSessionCheck(Date.now());

                }
            } else {
                const tokenExpiryInSeconds = this.props.tokenExpiryMinutes * 60;
                const showExpiryCountdownAfterSeconds = tokenExpiryInSeconds - this.props.tokenExpiryCountdownSeconds;

                if (secondsSinceLastInteraction >= showExpiryCountdownAfterSeconds) {
                    this.props.showSessionExpiryWarning();
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSessionExpired: state.CurrentSession.sessionExpired,
        lastInteractionTimeCheckIntervalMS: state.CurrentSession.lastInteractionTimeCheckIntervalMS,
        lastInteractionTime: state.CurrentSession.lastInteractionTime,
        lastSessionCheck : state.CurrentSession.lastSessionCheck ,
        timingsSetFromCookie: state.CurrentSession.timingsSetFromCookie,
        tokenValidityCheckFrequencySeconds: state.CurrentSession.tokenValidityCheckFrequencySeconds,
        tokenRefreshMinutesBeforeExpiry: state.CurrentSession.tokenRefreshMinutesBeforeExpiry,
        tokenExpiryMinutes: state.CurrentSession.tokenExpiryMinutes,
        tokenExpiryCountdownSeconds: state.CurrentSession.tokenExpiryCountdownSeconds
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSessionExpiryWarning: () => {
            dispatch({
                type: SHOW_SESSION_EXPIRY_WARNING,
                showWarning: true
            });

            dispatch({
                type: ACTIVATE_SESSION_EXPIRY_COUNTDOWN
            });
        },
        setTokenExpiryCountdownSeconds: (seconds) => {
            dispatch({
                type: SET_TOKEN_EXPIRY_COUNTDOWN_SECONDS,
                seconds
            });
        },
        updateTokenExpiryTimings: (timings) => {
            dispatch({
                type: UPDATE_TOKEN_EXPIRY_TIMINGS,
                timings
            });
        },
        setLastInteractionTime: (lastInteractionTime) => {
            dispatch({
                type: SET_LAST_INTERACTION_TIME,
                lastInteractionTime
            });
        },
        setLastSessionCheck: (timestamp) => {
            dispatch({
                type: SET_LAST_SESSION_CHECK,
                timestamp
            });
        },
        updateSessionExpiryTime: (tokenExpiryMinutes) => {
            let expiryTime = new Date();
            expiryTime.setSeconds(expiryTime.getSeconds() + (tokenExpiryMinutes*60));

            dispatch(setSessionExpiryTime(expiryTime));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InteractionMonitor);
