import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    SET_USERNAME,
    SET_SESSION_ID,
    SHOW_PASSWORD_CHANGE,
    SET_PASSWORD_CHANGE_COMPLETE,
    SET_USER_PERMISSIONS
} from './constants';


export const loginUser = (username, password) => ({
    type: LOGIN_USER,
    payload: { username, password },
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});

export const registerUser = (fullname, email, password) => ({
    type: REGISTER_USER,
    payload: { fullname, email, password },
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (username) => ({
    type: FORGET_PASSWORD,
    payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error,
});

export const setUsername= (username) => ({
    type: SET_USERNAME,
    username: username
});

export const setSessionId = (sessionId) => ({
    type: SET_SESSION_ID,
    sessionId: sessionId
});

export const showPasswordChange = () => ({
    type: SHOW_PASSWORD_CHANGE,
    activatePasswordChange: true
});

export const hidePasswordChange = () => ({
    type: SHOW_PASSWORD_CHANGE,
    activatePasswordChange: false
});

export const setPasswordChangeComplete = () => ({
    type: SET_PASSWORD_CHANGE_COMPLETE,
    changePasswordComplete: true
});

export const unsetPasswordChangeComplete = () => ({
    type: SET_PASSWORD_CHANGE_COMPLETE,
    changePasswordComplete: false
});
