import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { SESSION_EXPIRED } from '../redux/currentSession/constants';

class SessionExpiredModal extends React.Component {
    constructor(props) {
        super(props);

        this.completeEndSession = this.completeEndSession.bind(this);
    }

    completeEndSession() {
        this.props.setSessionExpired(false);
    }

    render() {
        return (
            <Modal id="sessionExpiredModal" isOpen={this.props.isSessionExpired} className="" size="lg" backdrop="static" keyboard={false}>
                <ModalHeader>Session Expired</ModalHeader>
                <ModalBody>
                    <div className="text-center m-auto">
                        <Alert color="success">
                            Your session has expired due to inactivity. <br />
                        </Alert>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.completeEndSession}>Back to Login</Button>
                </ModalFooter>
            </Modal>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isSessionExpired: state.CurrentSession.sessionExpired
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSessionExpired: (isExpired) => {
            dispatch({
                type: SESSION_EXPIRED,
                expired: isExpired
            });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpiredModal);