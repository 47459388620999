import {
    LOCATION_CHANGED,
    SET_ACTIVE_PAGE,
    SET_USER_PERMISSIONS    
} from "./constants";

const InitialState = {
    currentLocation: "",
    activeSubMenu: "",
    activePage: "",
    isCollapsed: false
};

const AppMenu = (state = InitialState, action) => {
    switch (action.type) {
        case LOCATION_CHANGED:
            const newURL = action.payload.location.pathname;
            const activeSubMenu = GetActiveSubmenu(newURL);

            return {
                ...state,
                currentLocation: newURL,
                activeSubMenu
            };

        case SET_ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.activePage
            };

        case SET_USER_PERMISSIONS:
            const menuVisibility = getMenuVisibility(action.userPermissions);

            return {
                ...state,
                showFinancialItems: menuVisibility.Financial,
                showUtilisationItems: menuVisibility.Utilisation,
                showTechnicalItems: menuVisibility.Technical,
                showServicedEntityItems: menuVisibility.ServicedEntity
            };

        default:
            return state;
    }
};

const GetActiveSubmenu = (newURL) => {
    const newURL_LowerCase = newURL.toLowerCase()

    if (newURL_LowerCase.indexOf("/fleetsummary/") === 0) {
        return "FleetSummary";
    }

    if (newURL_LowerCase.indexOf("/leaseaccount/") === 0) {
        return "LeaseAccount"; 
    }

    if (newURL_LowerCase.indexOf("/supplementalrent/") === 0) {
        return "SuppRent"; 
    }

    if (newURL_LowerCase.indexOf("/utilisation/") === 0) {
        return "Utilisation"; 
    }

    if (newURL_LowerCase.indexOf("/tms/") === 0) {
        return "TMS"; 
    }

    return "";
}

const getMenuVisibility = (userPermissions) => {
    return {
        Financial: userPermissions.FinancialAccess === "Y",
        Utilisation: userPermissions.CustomerAccess === "Y",
        Technical: userPermissions.TechnicalAccess === "Y",
        ServicedEntity: userPermissions.ServicedEntityAccess === "Y"
    };
};

export default AppMenu;
