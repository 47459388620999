import React from 'react';
import { withRouter } from 'react-router-dom';

class RouteMonitor extends React.Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.props.onRouteChange && this.props.onRouteChange();
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return (
            <div>{this.props.children}</div>
        );
    }
}

export default withRouter(RouteMonitor);
