import { getUserToken } from '../helpers/authUtils';

const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

async function fetchJSON(url, options = {}) {
    options = {
        ...{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + getUserToken()
            }
        },...options
    };

    let fullUrl = baseUrl + url;
    return await fetch(fullUrl, options)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error(response);
            }
        })
        .then(data => {
            let res = {
                loading: false,
                data: data,
                error: false
            }
            return res;
        })
        .catch(error => {
            //can redirect but this hides message, commenting out for now
            //window.location.href = baseUrl + '/portal/error/error-500';
            return {
                loading: false,
                data: [],
                error: true
            }
        });
};

async function postJSON(url, content = {}, options = {}) {
    options = {
        ...{
            method: 'POST',
            body: content,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': 'Bearer ' + getUserToken()
            }
        }, ...options
    };
    let fullUrl = baseUrl + url;

    return await fetch(fullUrl, options)
        .then(response => {
            if (response.ok) {
                //note expecting non empty body
                return response.json();
            }
            else {
                throw new Error(response);
            }
        })
        .then(data => {
            let res = {
                loading: false,
                data: data,
                error: false
            }
            return res;
        })
        .catch(error => {
            //can redirect but this hides message, commenting out for now
            //window.location.href = baseUrl + '/portal/error/error-500';
            return {
                loading: false,
                data: [],
                error: true
            }
        });
};

async function postForm(url, content = {}, options = {}) {
    options = {
        ...{
            method: 'PUT',
            body: content,
            headers: {
                'accept': 'application/json',
                'contentType': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + getUserToken()
            }
        }, ...options
    };
    let fullUrl = baseUrl + url;

    return await fetch(fullUrl, options)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                throw new Error(response);
            }
        })
        .then(data => {
            let res = {
                loading: false,
                data: data,
                error: false
            }
            return res;
        })
        .catch(error => {
            //can redirect but this hides message, commenting out for now
            //window.location.href = baseUrl + '/portal/error/error-500';
            return {
                loading: false,
                data: [],
                error: true
            }
        });
};

async function downloadFile(docFileId, filename, options = {}) {
    options = {
        ...{
            method: 'GET',
            headers: {
                'Response-Type': 'blob',
                'Authorization': 'Bearer ' + getUserToken()
            }
        }, ...options
    };
    let fullUrl = `${baseUrl}/portal/Documents/DownloadDocument?docId=${docFileId}&fileName=${filename}`;
    return await fetch(fullUrl, options)
        .then(response => {
            if (response.ok) {
                return response.blob();
            }
            else {
                throw new Error(response);
            }
        })
        .then(data => {
            let res = {
                downloading: false,
                data: data,
                error: false
            }

            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(data);
            var attr = document.createAttribute("download");
            a.setAttributeNode(attr);
            a.style.display = 'none';
            a.download = decodeURIComponent(filename);
            document.body.appendChild(a);
            a.click();
            a.remove();

            return res;
        })
        .catch(error => {
            //can redirect but this hides message, commenting out for now
            //window.location.href = baseUrl + '/portal/error/error-500';
            return {
                downloading: false,
                data: [],
                error: true
            }
        });
};

async function downloadReport(filename, url, options = {}) {
    options = {
        ...{
            method: 'GET',
            headers: {
                'Response-Type': 'blob',
                'Authorization': 'Bearer ' + getUserToken()
            }
        }, ...options
    };
    let fullUrl = `${baseUrl}/portal/` + url;
    return await fetch(fullUrl, options)
        .then(response => {
            if (response.ok) {
                return response.blob();
            }
            else {
                throw new Error(response);
            }
        })
        .then(data => {
            let res = {
                downloading: false,
                data: data,
                error: false
            }

            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(data);
            var attr = document.createAttribute("download");
            a.setAttributeNode(attr);
            a.style.display = 'none';
            a.download = decodeURIComponent(filename);
            document.body.appendChild(a);
            a.click();
            a.remove();

            return res;
        })
        .catch(error => {
            //can redirect but this hides message, commenting out for now
            //window.location.href = baseUrl + '/portal/error/error-500';
            return {
                downloading: false,
                data: [],
                error: true
            }
        });
};

export { fetchJSON, postJSON, postForm, downloadFile, downloadReport };
