import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import RouteMonitor from "./RouteMonitor";

import { ConnectedRouter } from 'connected-react-router'
import { history } from "../redux/store";

// layout HOC
import withLayout from '../components/Layout';
import { allFlattenRoutes as routes } from './index';

const Routes = (props) => (
    // rendering the router with layout
    <ConnectedRouter history={history}>
        <RouteMonitor onRouteChange={props.onRouteChange}>
            <Switch>
                {routes.map((route, index) => {
                    return (
                        !route.children && (
                            <route.route
                                key={index}
                                path={route.path}
                                roles={route.roles}
                                exact={route.exact}
                                component={withLayout(props => {
                                    return <route.component {...props} />;
                                })}></route.route>
                        )
                    );
                })}
            </Switch>
        </RouteMonitor>
    </ConnectedRouter>
);

export default Routes;
