// @flow

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import Layout from './layout/reducers';

import { Auth } from './auth/reducers';

import {
    CurrentSession
} from './currentSession/reducers';

import AppMenu from './appMenu/reducers';
import Utilisation from './utilisation/reducers';
import Notifications from './notifications/reducers';

export const createRootReducer = (history) => {
    return combineReducers({
        router: connectRouter(history),
        CurrentSession,
        Auth,
        AppMenu,
        Layout,
        Notifications,
        Utilisation
    });
}

