/* APP MENU */
export const INIT_MENU = 'INIT_MENU';
export const INIT_MENU_SUCCESS = 'INIT_MENU_SUCCESS';
export const CHANGE_ACTIVE_MENU_FROM_LOCATION = 'CHANGE_ACTIVE_MENU_FROM_LOCATION';
export const CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS = 'CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS';

export const LOCATION_CHANGED = "@@router/LOCATION_CHANGE";

export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
