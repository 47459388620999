// currentSession reducer

import {
    ACTIVATE_SESSION_EXPIRY_COUNTDOWN,
    CANCEL_SESSION_EXPIRY_COUNTDOWN,
    SHOW_SESSION_EXPIRY_WARNING,
    SET_TOKEN_EXPIRY_COUNTDOWN_SECONDS,
    SESSION_EXPIRED,
    SESSION_EXPIRING_IN_SECONDS,
    SET_LAST_SESSION_CHECK,
    SET_LAST_INTERACTION_TIME,
    SET_SESSION_EXPIRY_TIME,
    UPDATE_TOKEN_EXPIRY_TIMINGS
} from './constants';

const InitialState = {
    lastInteractionTime: Date.now(),
    lastInteractionTimeCheckIntervalMS: 10000,
    lastSessionCheck: Date.now(),
    sessionExpired: false,
    sessionExpiresAt: null,
    sessionExpiringInSeconds: undefined,
    sessionExpiryCountdownActive: false,
    sessionExpiryCountdownSeconds: 120,
    showSessionExpiryWarning: false,
    timingsSetFromCookie: false,
    tokenValidityCheckFrequencySeconds: 30,
    tokenRefreshMinutesBeforeExpiry: 10,
    tokenExpiryMinutes: 15,
    tokenExpiryCountdownSeconds: 120
};


export const CurrentSession = (state = InitialState, action) => {
    switch (action.type) {
        case UPDATE_TOKEN_EXPIRY_TIMINGS:
            return {
                ...state,
                ...action.timings
            };

        case SET_LAST_SESSION_CHECK:
            return {
                ...state,
                lastSessionCheck: action.timestamp
            };

        case SHOW_SESSION_EXPIRY_WARNING:
            return {
                ...state,
                showSessionExpiryWarning: action.showWarning
            };
            
        case ACTIVATE_SESSION_EXPIRY_COUNTDOWN:
            return {
                ...state,
                sessionExpiryCountdownActive: true
            };

        case CANCEL_SESSION_EXPIRY_COUNTDOWN:
            return {
                ...state,
                sessionExpiryCountdownActive: false
            };
            
        case SET_TOKEN_EXPIRY_COUNTDOWN_SECONDS:
            return {
                ...state,
                tokenExpiryCountdownSeconds: action.seconds
            };
            
        case SESSION_EXPIRED:
            return {
                ...state,
                sessionExpired: action.expired,
                timingsSetFromCookie: false
            };
            
        case SESSION_EXPIRING_IN_SECONDS:
            return {
                ...state,
                sessionExpiringInSeconds: action.seconds
            };
            
        case SET_LAST_INTERACTION_TIME:
            return {
                ...state,
                lastInteractionTime: action.lastInteractionTime
            };

        case SET_SESSION_EXPIRY_TIME:
            return {
                ...state,
                sessionExpiresAt: action.expiryTime
            };

        default:
            return state;
    }
};
