// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { createRootReducer } from './reducers';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

const history = createBrowserHistory({ basename: "/portal" });

const configureStore = (initialState) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const reducers = createRootReducer(history);

    const store = createStore(
        reducers,
        initialState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history),
                ...middlewares
            )
        )
    );

    sagaMiddleware.run(sagas);
    return store;
};

export {
    history,
    configureStore
}; 