import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledAlert } from 'reactstrap';
import { postJSON } from './helpers/api';

export default class ErrorBoundary extends Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    };
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        this.postLogError(error, errorInfo);
        this.setState({ errorInfo });
    }

    async postLogError(error, errorInfo) {
        let url = '/portal/Miscellaneous/LogError';

        let content = {
            Message: error.message,
            Stack: error.stack
        };
        let json = JSON.stringify(content);
        await postJSON(url, json);
    }

    render() {
        const { hasError, errorInfo } = this.state;
        if (hasError) {
            return (
                <UncontrolledAlert color='danger' key='1'>
                    <i className="dripicons-wrong mr-2" /> An error has occurred loading the data. Please reload the page or contact <a href={`mailto:CustomerSupport@AerCap.com`}>AerCap Customer Support</a>
                </UncontrolledAlert>
            );
        }
        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};