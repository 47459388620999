// auth reducer

import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    SET_USERNAME,
    SET_SESSION_ID,
    SHOW_PASSWORD_CHANGE,
    SET_PASSWORD_CHANGE_COMPLETE
} from './constants';


const INIT_STATE = {
    user: '',
    loading: false,
    username: '',
    sessionId: '',
    changePasswordVisible: false,
    changePasswordComplete: false
};

export const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true
            };

        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: null
            };

        case LOGIN_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case REGISTER_USER:
            return {
                ...state,
                loading: true
            };

        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: null
            };

        case REGISTER_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case LOGOUT_USER:
            return {
                ...state,
                user: null
            };

        case FORGET_PASSWORD:
            return {
                ...state,
                loading: true
            };

        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordResetStatus: action.payload,
                loading: false,
                error: null
            };

        case FORGET_PASSWORD_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case SET_USERNAME:
            return {
                ...state,
                username: action.username
            };

        case SET_SESSION_ID:
            return {
                ...state,
                sessionId: action.sessionId
            };

        case SHOW_PASSWORD_CHANGE:
            return {
                ...state,
                changePasswordVisible: action.activatePasswordChange
            };

        case SET_PASSWORD_CHANGE_COMPLETE:
            return {
                ...state,
                changePasswordComplete: action.changePasswordComplete
            };

        default:
            return { ...state };
    }
};
