import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { 
    sessionExpired, 
    logout, 
    isUserAuthenticated 
} from '../helpers/authUtils';

import {
    CANCEL_SESSION_EXPIRY_COUNTDOWN,
    SESSION_EXPIRED,
    SESSION_EXPIRING_IN_SECONDS,
    SET_LAST_INTERACTION_TIME,
    SHOW_SESSION_EXPIRY_WARNING
} from '../redux/currentSession/constants';

import { setSessionExpiryTime } from '../redux/currentSession/actions';

class SessionExpiryWarningModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sessionExpiringInSeconds: undefined,
            sessionExpiringAt: undefined
        };

        this.decrementTimer = this.decrementTimer.bind(this);
        this.extendSession = this.extendSession.bind(this);
        this.endSession = this.endSession.bind(this);
    }

    componentDidMount() {
        this.intervalId = setInterval(this.decrementTimer, 1000);
    }

    decrementTimer() {
        if (this.props.sessionExpiryCountdownActive) {
            let sessionExpiringInSeconds = this.state.sessionExpiringInSeconds || this.props.sessionExpiryCountdownSeconds;

            if (this.props.sessionExpiresAt) {
                let currentTime = new Date();

                let secondsUntilTokenExpiryTime = Math.round((this.props.sessionExpiresAt - currentTime) / 1000);

                if (secondsUntilTokenExpiryTime < 0) {
                    this.endSession();
                } else if (secondsUntilTokenExpiryTime < sessionExpiringInSeconds) {
                    sessionExpiringInSeconds = secondsUntilTokenExpiryTime;
                }
            }
            
            if (this.props.sessionExpiringInSeconds === undefined && this.props.sessionExpiryCountdownSeconds > 0) {
                this.props.setSessionExpiringInSeconds(this.props.sessionExpiryCountdownSeconds);
            }
        

            if (this.props.sessionExpiringInSeconds > 0) {                
                this.props.setSessionExpiringInSeconds(this.props.sessionExpiringInSeconds - 1);
            }
        }

        if (this.props.sessionExpiringInSeconds <= 0) {
            this.endSession();
        }
    }

    extendSession() {
        isUserAuthenticated(null, null, true, this.props.updateSessionExpiryTime);
        this.props.hideSessionExpiryWarning();
        this.props.cancelSessionExpiryCountdown();
        this.props.setLastInteractionTime();
        
        this.props.setSessionExpiringInSeconds(this.props.sessionExpiryCountdownSeconds);
    }

    endSession() {
        // Don't change the order. Cookie must be dropped first before props changes.
        sessionExpired();
        this.props.setSessionExpired(true);
        this.props.hideSessionExpiryWarning();
        this.props.cancelSessionExpiryCountdown();
        this.props.setSessionExpiringInSeconds(this.props.sessionExpiryCountdownSeconds);
    }

    render() {
        return (
            <Modal id="sessionExpiryWarningModal" isOpen={this.props.displaySessionExpiryWarning} className="" size="lg" backdrop="static" keyboard={false}>
                <ModalHeader>Session Expiring Soon</ModalHeader>
                <ModalBody>
                    Your session is expiring soon.<br /><br />
                    Your session will timeout in <strong>{this.props.sessionExpiringInSeconds}</strong> seconds as you’ve been inactive
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.extendSession}>Stay logged in</Button>
                    <Button onClick={logout}>Logout</Button>
                </ModalFooter>
            </Modal>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        displaySessionExpiryWarning: state.CurrentSession.showSessionExpiryWarning,
        sessionExpiryCountdownActive: state.CurrentSession.sessionExpiryCountdownActive,
        sessionExpiryCountdownSeconds: state.CurrentSession.sessionExpiryCountdownSeconds,
        sessionExpiringInSeconds: state.CurrentSession.sessionExpiringInSeconds,
        sessionExpiresAt: state.CurrentSession.sessionExpiresAt
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancelSessionExpiryCountdown: () => {
            dispatch({
                type: CANCEL_SESSION_EXPIRY_COUNTDOWN
            });
        },
        hideSessionExpiryWarning: () => {
            dispatch({
                type: SHOW_SESSION_EXPIRY_WARNING,
                showWarning: false
            });
        },
        setSessionExpired: (isSessionExpired) => {
            dispatch({
                type: SESSION_EXPIRED,
                expired: isSessionExpired
            });
        },
        setSessionExpiringInSeconds: (seconds) => {
            dispatch({
                type: SESSION_EXPIRING_IN_SECONDS,
                seconds
            });
        },
        setLastInteractionTime: (lastInteractionTime) => {
            dispatch({
                type: SET_LAST_INTERACTION_TIME,
                lastInteractionTime: lastInteractionTime || Date.now()
            });
        },
        updateSessionExpiryTime: (tokenExpiryMinutes) => {
            let expiryTime = new Date();
            expiryTime.setSeconds(expiryTime.getSeconds() + (tokenExpiryMinutes * 60));

            dispatch(setSessionExpiryTime(expiryTime));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpiryWarningModal);