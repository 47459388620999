/* CurrentSession */

export const ACTIVATE_SESSION_EXPIRY_COUNTDOWN = "ACTIVATE_SESSION_EXPIRY_COUNTDOWN";
export const CANCEL_SESSION_EXPIRY_COUNTDOWN = "CANCEL_SESSION_EXPIRY_COUNTDOWN";
export const SET_LAST_INTERACTION_TIME = 'SET_LAST_INTERACTION_TIME';
export const SET_LAST_SESSION_CHECK = 'SET_LAST_SESSION_CHECK';
export const SET_TOKEN_EXPIRY_COUNTDOWN_SECONDS = 'SET_TOKEN_EXPIRY_COUNTDOWN_SECONDS';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const SESSION_EXPIRING_IN_SECONDS = 'SESSION_EXPIRING_IN_SECONDS';
export const SET_SESSION_EXPIRY_TIME = 'SET_SESSION_EXPIRY_TIME';
export const SHOW_SESSION_EXPIRY_WARNING = 'SHOW_SESSION_EXPIRY_WARNING';
export const UPDATE_TOKEN_EXPIRY_TIMINGS = 'UPDATE_TOKEN_EXPIRY_TIMINGS';
