
import {
    QUERY_MANAGED_ASSETS,
    TOGGLE_MODAL_VISIBILITY,
    UPDATE_UTILISATION
} from './constants';

const INIT_STATE = {
    assetList: [],
    registrationData: [],
    loading: false,
    saving: false,
    savedSuccessfully: false,
    errorSaving: false,
    filterBy: 'Lease',
    selectedMonth: '',
    months: [],
    noOfMonths: 1, // default to monthly data entry
    assetListLease: [],
    assetListAircraft: [],
    assetListEngine: [],
    aircraftEngineNumbers: [],
    lastMessageView: '',
    standaloneEngineIds: []
};

const Utilisation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_MODAL_VISIBILITY:
            let currentVisibility = state[action.modalId];

            return {
                ...state,
                [action.modalId]: !currentVisibility
            };

        case QUERY_MANAGED_ASSETS:
            return {
                ...state,
                assetList: [],
                assetListLease: [],
                assetListAircraft: [],
                assetListEngine: [],
                aircraftEngineNumbers: [],
                standaloneEngineIds: [],
                loading: true,
                filterBy: action.filterBy
            };

        case UPDATE_UTILISATION:
            return {
                ...state,
                ...action.updatedFields
            };            

        default:
            return state;
    }
};

export default Utilisation;
