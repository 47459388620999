// @flow
import {
    ERROR_QUERYING_NOTIFICATIONS,
    INITIAL_CHECK_FOR_PUBLICATION_CHANGES_COMPLETE,
    QUERYING_NOTIFICATIONS,
    RECEIVED_NOTIFICATIONS
} from './constants';


const InitialState = {
    notifications: [],
    loading: false,
    error: null,
    initialPublicationCheckDone: false,
    lastRetrievalTime: null,
    lastRetrievalTimeFormatted: "Not Yet Retrieved"
};

const Notifications = (state = InitialState, action) => {
    switch (action.type) {
        case QUERYING_NOTIFICATIONS:
            return {
                ...state,
                loading: true
            };

        case ERROR_QUERYING_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                error: action.errorMessage
            };

        case RECEIVED_NOTIFICATIONS:
            let currentDateTimeAsTicks = Date.now();
            let formattedCurrentDateTime = new Date(currentDateTimeAsTicks);

            return {
                ...state,
                notifications: action.notifications,
                loading: false,
                lastRetrievalTime: Date.now(),
                lastRetrievalTimeFormatted: formattedCurrentDateTime
            };

        case INITIAL_CHECK_FOR_PUBLICATION_CHANGES_COMPLETE:
            return {
                ...state,
                initialPublicationCheckDone: true
            };

        default:
            return state;
    }
};

export default Notifications;
