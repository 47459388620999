/* AUTH */

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const SET_USERNAME = 'SET_USERNAME';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SHOW_PASSWORD_CHANGE = 'SHOW_PASSWORD_CHANGE';
export const SET_PASSWORD_CHANGE_COMPLETE = 'SET_PASSWORD_CHANGE_COMPLETE';


